import './App.css';
import Dashboard from './Dashboard';
import Header from './Header';

function App() {
  return (
    <>
      <Header/>
      <Dashboard/>
    </>
    
  );
}

export default App;
